import { toTypedSchema } from '@vee-validate/zod';
import zod from 'zod';

const REQUIRED_ERROR_MSG = 'Dit veld is verplicht';

export const inputRules = {
  required: toTypedSchema(zod.string({ required_error: REQUIRED_ERROR_MSG, invalid_type_error: REQUIRED_ERROR_MSG }).min(1, { message: REQUIRED_ERROR_MSG })),

  requiredNumber: toTypedSchema(zod.number({ required_error: REQUIRED_ERROR_MSG, invalid_type_error: REQUIRED_ERROR_MSG }).min(1, { message: REQUIRED_ERROR_MSG })),

  listRequired: toTypedSchema(zod.string({ required_error: REQUIRED_ERROR_MSG }).array().min(1, { message: REQUIRED_ERROR_MSG })),

  email: toTypedSchema(zod.union([zod.string().email({ message: 'Ongeldig e-mailadres' }).optional(), zod.literal('')])),

  emailRequired: toTypedSchema(zod.string({ required_error: REQUIRED_ERROR_MSG }).email({ message: 'Ongeldig e-mailadres' })),

  // TODO: add file size validation
  file: toTypedSchema(zod.any()
    .refine((file) => !!file, 'Bestand is vereist')),

  fileImage: toTypedSchema(zod.any().refine((file) => !!file, 'Bestand is vereist').refine((file) => {
    return ['image/gif', 'image/jpeg', 'image/png'].includes(file?.type || '');
  }, 'Moet een afbeeldingsbestand')),
};
